/* eslint-disable no-empty */
export const formatTime = (value) => {
  const minutes = parseInt(value / 60)
  const seconds = parseInt(value % 60)
  const minToTwoDec = minutes < 10 ? `0${minutes}` : minutes
  const secToTwoDec = seconds < 10 ? `0${seconds}` : seconds

  return `${minToTwoDec}:${secToTwoDec}`
}

export const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect()

  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left < window.innerWidth &&
    rect.top < window.innerHeight
  )
}

export const isElementVisible = (ele, container) => {
  const { bottom, top } = ele.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  return top > containerRect.top && bottom < containerRect.bottom
}

export const trimExtraSpace = (str) => {
  return str.replace(/\s+/g, ' ').trim()
}

export const getOffsetTop = (el) => {
  return el ? el.offsetTop + getOffsetTop(el.offsetParent) : 0
}

export const scrollIntoView = (el, options = {}, extraOffset = 0) => {
  try {
    const mobileHeaderHeight =
      document.querySelector('.header-container')?.clientHeight

    const mediumBreakpoint = 992
    const offset = window.innerWidth < mediumBreakpoint ? mobileHeaderHeight : 0

    const y = getOffsetTop(el) - offset - extraOffset

    window.scrollTo({ top: y, ...options })
  } catch (error) {}
}

export const getImagePlaceholder = (fill = '#e2e7ea') => {
  return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='544' height='836'%3E%3Crect width='544' height='836' style='fill:${encodeURIComponent(
    fill,
  )}' /%3E%3C/svg%3E`
}

let throttlePause
export const throttle = (callback, time) => {
  if (throttlePause) return

  callback()
  throttlePause = true

  setTimeout(() => {
    throttlePause = false
  }, time)
}
